import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className="flex-1 w-full mx-auto">
        {children}
      </main>

      <footer className="bg-blue-900">
        <nav className="text-white flex md:flex-row flex-col items-center justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <div className="flex">
          <span>
          © {new Date().getFullYear()} REG Digital Media LLC 
          </span>
          <div className="mx-2">|</div>
          <a className="text-white" href="/privacy-policy">Privacy Policy</a>
          </div>

          <span className="text-white">
          Built with
          {` `}
          <a className="text-white" href="https://www.gatsbyjs.org">Gatsby</a>
          </span>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
